<template>
  <SfSidebar
    class="filters-sidebar"
    :visible="isFilterSidebarOpen"
    title="I tuoi preferiti"
    @close="toggleFilterSidebar(filterSidebarCategoryId)"
    position="right"
  >
    <FiltersSidebarTop :category-id="filterSidebarCategoryId" />
    <CategoryOrderByMobile :category-id="filterSidebarCategoryId" />
    <CategoryFilters :category-id="filterSidebarCategoryId" />
    <FiltersSidebarBottom :category-id="filterSidebarCategoryId" />
  </SfSidebar>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import { CategoryFilters, CategoryOrderByMobile } from '~/components/Category';
import {
  FiltersSidebarBottom,
  FiltersSidebarTop,
} from './FiltersSidebarInternal';
import SfSidebar from '../storefront-ui/organisms/SfSidebar.vue';

export default defineComponent({
  name: 'FiltersSidebar',
  components: {
    SfSidebar,
    CategoryFilters,
    CategoryOrderByMobile,
    FiltersSidebarBottom,
    FiltersSidebarTop,
  },
  props: {},
  setup() {
    const {
      isFilterSidebarOpen,
      toggleFilterSidebar,
      filterSidebarCategoryId,
    } = useUiState();

    return {
      isFilterSidebarOpen,
      toggleFilterSidebar,
      filterSidebarCategoryId,
    };
  },
});
</script>

<style lang="scss">
.filters-sidebar {
  position: relative;
  --sidebar-z-index: 4;
  --overlay-z-index: 4;
  .sf-sidebar {
    &__content {
      padding: 0;
      display: block;
    }
    &__aside {
      .sf-bar.smartphone-only {
        display: none;
      }
    }
    &__top {
      padding: 0;
    }
  }
  .sf-accordion-item__header {
    padding: 1rem !important;
  }
}
</style>
