<template>
  <SfSidebar
    class="wishlist-sidebar"
    :visible="isWishlistSidebarOpen"
    title="I tuoi preferiti"
    @close="toggleWishlistSidebar"
    position="right"
  >
    <template #circle-icon="{ close }">
      <div class="wishlist-sidebar__top-right" @click="close">
        <div
          class="wishlist-sidebar__top-right__new"
          @click="toggleCreateWishlistModal"
        >
          <PlusIcon
            class="wishlist-sidebar__top-right__new__icon"
            v-if="isAuthenticated"
          />
          <span
            class="wishlist-sidebar__top-right__new__label"
            v-if="isAuthenticated"
          >
            Nuova lista preferiti
          </span>
        </div>
        <Cross @click="close" />
      </div>
    </template>
    <template #content-top>
      <div class="wishlist-sidebar__top" v-if="isAuthenticated">
        <CustomRichSelect
          class="wishlist-sidebar__top__selector"
          select-name="wishlist-sidebar-selector"
          select-title="Seleziona una lista preferiti"
          black-outline
          specific-width="100%"
          :select-options="selectableUserWishlists"
          :selected-value="(wishlist && wishlist.uid) || null"
          :reload-options="loadUserWishlists"
          @selectChange="(w) => handleWishlistChange(w)"
          v-if="selectableUserWishlists.length > 0 && !loading"
          :key="`wishlist-sidebar-selector-${
            (wishlist && wishlist.uid) || Date.now()
          }`"
        />
        <CustomInput
          v-model="searchTerm"
          class="wishlist-sidebar__top__search"
          input-name="wishlist-sidebar-search"
          input-id="wishlist-sidebar-search"
          input-placeholder="Nome/SKU prodotto..."
          input-label="Cerca nei preferiti"
          :override-style="{ width: '100%' }"
        />
      </div>
    </template>
    <LoadingDots v-if="loading" />
    <div v-if="showWishlistItems" class="wishlist-sidebar__items">
      <WishlistSidebarItem
        v-for="(wishlistItem, wishlistItemIndex) in filteredWishlistItems"
        :key="`wishlist-sidebar-${wishlist.uid}-item-${wishlistItemIndex}-${wishlistItem.uid}`"
        :wishlist-item="wishlistItem"
        :wishlist-item-uid="wishlistItem.uid"
        :wishlist-item-grn="wishlistItem.item_grn"
        :wishlist-loading="loading"
        @update:quantity="handleWrongQuantity"
      />
    </div>
    <div v-if="!isAuthenticated && !loading" class="wishlist-sidebar__empty">
      Effettua l'accesso per visualizzare i tuoi prodotti preferiti
      <CustomButton @click="toggleLoginAndRegisterModal"> Accedi </CustomButton>
    </div>
    <template #content-bottom>
      <transition name="sf-fade">
        <div
          v-if="
            isAuthenticated &&
            !loading &&
            !cartLoading &&
            wishlist &&
            wishlist.items &&
            wishlist.items.items.length > 0
          "
          class="wishlist-sidebar__bottom"
        >
          <CustomButton
            class="wishlist-sidebar__bottom__add-all"
            specific-width="100%"
            @click="handleAddAllToCart"
            theme="black"
            :disabled="!canAddAllToCart"
          >
            {{ $gt('Add all to cart') }}
          </CustomButton>
        </div>
        <div v-else>
          <CustomButton specific-width="100%" @click="toggleWishlistSidebar">
            {{ $gt('Back to shopping') }}
          </CustomButton>
        </div>
      </transition>
    </template>
  </SfSidebar>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted,
} from '@nuxtjs/composition-api';
import { SfSidebar } from '@storefront-ui/vue';
import {
  useUiState,
  useUserWishlists,
  useCart,
  useTranslation,
} from '~/composables';
import {
  CustomSelect,
  CustomInput,
  CustomButton,
  LoadingDots,
} from '~/components/General/FormElements';
import { Cross, PlusIcon } from '~/components/General/Icons';
import CustomRichSelect from '~/components/Sidebars/WishlistsSelect.vue';
import { WishlistSidebarItem } from './WishlistSidebarInternal';

export default defineComponent({
  name: 'WishlistSidebar',
  components: {
    CustomRichSelect,
    SfSidebar,
    Cross,
    WishlistSidebarItem,
    CustomInput,
    PlusIcon,
    CustomButton,
    LoadingDots,
  },
  props: {},
  setup() {
    const { $gt } = useTranslation('translations');
    const {
      isWishlistSidebarOpen,
      toggleWishlistSidebar,
      toggleCreateWishlistModal,
      toggleLoginAndRegisterModal,
      toggleCartSidebar,
    } = useUiState();
    const searchTerm = ref();

    const {
      userWishlists,
      wishlist,
      changeWishlist,
      selectableUserWishlists,
      loading,
      loadWishlist,
      loadUserWishlists,
      isAuthenticated,
      bulkUpdateItemsInWishlist,
    } = useUserWishlists();

    const { bulkAddItems, loading: cartLoading, cartItems } = useCart();

    const getItemName = (item) => {
      if (item.item_grn.includes('/')) {
        const variant = item.product.variants.find(
          (v) => v.product.uid === item.item_grn
        );
        return variant ? variant.product.name : item.product.name;
      }
      return item.product.name;
    };

    const filteredWishlistItems = computed(() => {
      if (!wishlist?.value || !wishlist?.value?.items?.items) return [];
      if (!searchTerm.value || searchTerm?.value?.length === 0)
        return wishlist.value.items.items
          .filter(Boolean)
          .sort((a, b) => getItemName(a).localeCompare(getItemName(b)));
      const lowerSearchTerm = searchTerm.value.toLowerCase();
      return wishlist.value.items.items
        .filter(
          (item) =>
            item.product.name.toLowerCase().includes(lowerSearchTerm) ||
            item.product?.variants?.some((variant) =>
              variant.product.merchant_sku
                .toLowerCase()
                .includes(lowerSearchTerm)
            )
        )
        .filter(Boolean)
        .sort((a, b) => getItemName(a).localeCompare(getItemName(b)));
    });

    const showWishlistItems = computed(() => {
      return (
        isAuthenticated &&
        wishlist.value &&
        wishlist.value?.items &&
        wishlist.value?.items?.items?.length > 0
      );
    });

    // Split wishlist items into items that are already in cart and items that are not.
    const splitWishlistItems = () => {
      const itemsInCart = [];
      const itemsNotInCart = [];

      filteredWishlistItems.value.forEach((item) => {
        const isInCart = cartItems.value?.some(
          (cartItem) => cartItem?.product?.sku === item?.item_grn
        );
        if (isInCart) {
          itemsInCart.push(item);
        } else {
          itemsNotInCart.push(item);
        }
      });

      return { itemsInCart, itemsNotInCart };
    };

    const canAddAllToCart = computed(
      () =>
        filteredWishlistItems.value?.every((item) =>
          item.item_grn.includes('/')
        ) &&
        filteredWishlistItems.value?.length > 0 &&
        !filteredWishlistItems.value?.every((item) => item.qty === 0)
    );

    onMounted(async () => {
      if (!userWishlists.value || !wishlist.value) {
        await loadWishlist();
      }
    });

    const handleWishlistChange = async (w) => {
      await changeWishlist(w);
    };

    // The existing wishlists all have items with quantity 1. Handle venditamultiplidiarticoliweb
    const wrongQuantities = ref({});
    const handleWrongQuantity = (qtyObj) => {
      wrongQuantities.value = {
        ...wrongQuantities.value,
        ...qtyObj,
      };
    };

    const handleAddAllToCart = async () => {
      const splitWishlistItemsObject = splitWishlistItems();
      const { itemsInCart, itemsNotInCart } = splitWishlistItemsObject;

      if (itemsInCart.length > 0 !== itemsNotInCart.length > 0) {
        const addableItems = wishlist.value.items.items.filter(
          (item) => item.qty > 0
        );
        await bulkAddItems(
          addableItems.map((item) => {
            // Add variant grn to the product object since we don't have the selected variant in the wishlist item.
            // Bit of a workaround, but it avoids handling a custom type in composables for the wishlist item.
            // Passing the variant as product is not an option since its type is SimpleProduct so there's no way to
            // tell the difference between a real simple product and a configurable variant product.
            const product = {
              ...item.product,
              configurable_product_options_selection: {
                variant: { sku: item.item_grn },
              },
            };
            return {
              product,
              // Force the quantity to venditamultiplidiarticoliweb if venditamultiplidiarticoliweb > wishlist quantity
              quantity: wrongQuantities.value?.[item.item_grn] || item.qty,
            };
          }),
          false
        );
        await bulkUpdateItemsInWishlist(
          addableItems.map((item) => {
            return {
              uid: item.uid,
              quantity: 0,
            };
          })
        );
        toggleWishlistSidebar();
        toggleCartSidebar();
        return;
      }

      const filteredSplitAddableItems = [
        ...itemsInCart.filter((item) => item.qty > 0),
        ...itemsNotInCart.filter((item) => item.qty > 0),
      ];
      // Split add to cart if not all wishlist items are in cart already. Workaround for bug in bulk operation, working
      // only if all or any of the items are in cart.
      // FixMe: remove this when the bug is fixed.
      await bulkAddItems(
        itemsInCart
          .filter((item) => item.qty > 0)
          .map((item) => {
            const product = {
              ...item.product,
              configurable_product_options_selection: {
                variant: { sku: item.item_grn },
              },
            };
            return {
              product,
              quantity: wrongQuantities.value?.[item.item_grn] || item.qty,
            };
          }),
        false
      );
      await bulkAddItems(
        itemsNotInCart
          .filter((item) => item.qty > 0)
          .map((item) => {
            const product = {
              ...item.product,
              configurable_product_options_selection: {
                variant: { sku: item.item_grn },
              },
            };
            return {
              product,
              quantity: wrongQuantities.value?.[item.item_grn] || item.qty,
            };
          }),
        false
      );
      await bulkUpdateItemsInWishlist(
        filteredSplitAddableItems.map((item) => {
          return {
            uid: item.uid,
            quantity: 0,
          };
        })
      );
      toggleWishlistSidebar();
      toggleCartSidebar();
    };

    return {
      $gt,
      isWishlistSidebarOpen,
      toggleWishlistSidebar,
      handleWishlistChange,
      userWishlists,
      selectableUserWishlists,
      wishlist,
      searchTerm,
      filteredWishlistItems,
      loading,
      cartLoading,
      toggleCreateWishlistModal,
      isAuthenticated,
      toggleLoginAndRegisterModal,
      loadUserWishlists,
      handleAddAllToCart,
      handleWrongQuantity,
      canAddAllToCart,
      showWishlistItems,
    };
  },
});
</script>

<style lang="scss">
.wishlist-sidebar {
  --sidebar-z-index: 4;
  --overlay-z-index: 4;
  .wishlist-sidebar__selector {
    margin-top: 1rem;
  }
  .sf-sidebar {
    &__content {
      padding-top: 0;
    }
    &__aside {
      .sf-bar.smartphone-only {
        display: none;
      }
    }
  }
  &__top-right {
    @include pointer;
    @include flex-center;
    gap: 2rem;
    padding: 1rem 1rem 0 1rem;
    justify-content: space-between;
    &__new {
      @include flex-center;
      gap: 0.5rem;

      &:hover {
        text-decoration: underline;
        text-decoration-color: var(--c-primary);
        &__icon {
          scale: 1.5;
        }
      }
      &__label {
        color: var(--c-primary);
        font-weight: 600;
        font-size: 1.2rem;
      }
    }
  }
  &__top {
    z-index: 1;
    position: relative;
    &__title {
      @include heading-h3;
    }
  }
  &__empty {
    @include text-20x26-regular;
    @include flex-column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
}
@include from-tablet-min {
  .wishlist-sidebar {
    .sf-sidebar {
      &__top {
        .sf-heading {
          &__title.h3 {
            font-size: 1.5vw;
          }
        }
      }
    }
    &__top-right {
      position: absolute;
      right: 2rem;
      top: 1.6rem;
      padding: 0;
      &__new {
        &__label {
          font-size: 1.1vw;
        }
      }
    }
    &__top {
      margin-top: 2rem;
    }
  }
}
@include from-desktop-min {
  .wishlist-sidebar {
    .sf-sidebar {
      &__aside {
        width: 30vw;
      }
    }
    &__top-right {
      top: 2rem;
    }
  }
}
</style>
